import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectDevice,
  setDeviceAsync,
  setUserListsAsync,
  selectStaffList,
  selectDoctorList,
  reset,
} from './deviceStateSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {
  Breadcrumb, BreadcrumbItem,
  DropdownItem, DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  Spinner,
  UncontrolledDropdown
} from "reactstrap";
import {useNavigate, useParams, Navigate} from 'react-router-dom'
import {Device, SystemStatus} from "../../../domain/Device";
import {siteTitlePrefix} from "../../../App";
import styles from './DeviceState.module.css';
import {DataElement, DataElementList} from "../../../components/DataElement";
import {EditDeviceDetails} from "../../modals/EditDeviceDetails";
import {editDeviceDetailsPopup} from "../../modals/editDeviceDetailsSlice";
import {AssignToPatient} from "../../modals/AssignToPatient";
import {
  assignToPatientPopup,
} from "../../modals/assignToPatientSlice";
import {ChangeCareContact} from "../../modals/ChangeCareContact";
import {changeCareContactPopup} from "../../modals/changeCareContactSlice";
import {selectRoles} from "../../me/meSlice";
import {AutoRefresh} from "../../../components/AutoRefresh";
import {DeviceChart} from "../chart/DeviceChart";
import {setHistoryAsync} from "../chart/deviceChartSlice";

import moment from "moment";

export function DeviceState() {
  const { account_id, device_id, patient_count } = useParams() as any
  const device = useSelector(selectDevice)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useSelector(selectAccessToken)
  const title = `Device`
  const staffList = useSelector(selectStaffList)
  const doctorList = useSelector(selectDoctorList)
  const roles = useSelector(selectRoles)

  useEffect(() => {
    dispatch(setUserListsAsync(accessToken, account_id))
    dispatch(setDeviceAsync(accessToken, account_id, device_id))
    document.title = siteTitlePrefix + title
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { dispatch(reset()) }
  }, [dispatch]);

  if (device) {
    if (patient_count === '-') {
      return (<Navigate to={`/devices/${account_id}/${device_id}/${device.patient_count}`} replace={true} />)
    } else if (patient_count !== device.patient_count) {
      // this is necessary to refresh all components, especially auto refresh
      return (<Navigate to={`/devices/${account_id}/${device_id}/-`} replace={true} />)
    }
  }

  return !(roles && device && patient_count !== '-') ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/current/devices/${account_id}`}>Devices</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          {device_id} ({device.online ? 'online': 'offline'})
          <AutoRefresh callback={() => {
            dispatch(setDeviceAsync(accessToken, account_id, device_id))
            dispatch(setHistoryAsync(accessToken, account_id, device_id, patient_count))
          }} timeout={2000} className="d-inline-block mx-4"/>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className={styles.deviceState} >
        <Navbar className='float-end m-0 p-0'>
          <Nav>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => dispatch(editDeviceDetailsPopup(device))} disabled={!roles.includes('admin')}>
                  Edit details
                </DropdownItem>
                <DropdownItem disabled>
                  View historical settings
                </DropdownItem>
                <DropdownItem onClick={() => {dispatch(assignToPatientPopup({device, doctorList}))}} disabled={!roles.includes('admin')}>
                  Assign to patient
                </DropdownItem>
                <DropdownItem onClick={() => {dispatch(changeCareContactPopup({device, staffList}))}} disabled={!roles.includes('admin')}>
                  Change care contact
                </DropdownItem>
                <DropdownItem disabled>
                  Manage notifications
                </DropdownItem>
                <DropdownItem onClick={() => navigate(`/devices/${account_id}/${device_id}/${device.patient_count}/chart`)}>
                  View chart details
                </DropdownItem>
                {device.respiratory_rate && <DropdownItem onClick={() => navigate(`/devices/${account_id}/${device_id}/${device.patient_count}/rox`)}>
                  View ROX chart
                </DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
        <DataElement title='device date' value={device.device_date}/>
        {'timezone' in device && <DataElement title='timezone' value={device.timezone}/>}
        <DataElement title='time correction' value={moment.duration(device.time_correction, 'seconds').humanize()}/>
        <DataElement title='corrected date' value={moment.utc(device.timestamp).local().toLocaleString()}/>
        <DataElement title='time drift' value={moment.duration(device.device_time_drift, 'seconds').humanize()}/>
        <br/>
        <DataElement title='model' value={device.model}/>
        <DataElement title='firmware version' value={device.firmware_version}/>
        <DataElement title='connectivity module' value={device.connection_id}/>
        {'location' in device && <DataElement title='location' value={device.location}/>}
        {'patient_name' in device && <DataElement title='patient' value={device.patient_name}/>}
        <br/>
        <DataElement title='system status' value={SystemStatus[device.system_status]}/>
        <DataElementList title='alarm codes' value={device.alarm_codes}/>
        <DataElement title='treatment time' value={device.user_therapy_time}/>
        <DataElement title='total therapy time' value={device.total_therapy_time}/>
        <DataElement title='patient count' value={device.patient_count}/>
        <DataElement title='first therapy date' value={device.first_therapy_date}/>
        <DataElement title='require oxygen' value={device.require_oxygen}/>
        <br/>
        <DataElement title='date of birth' value={device.patient_date_of_birth}/>
        <DataElement title='gender' value={device.patient_gender}/>
        <DataElement title='patient id' value={device.patient_id}/>
        <DataElement title='other patient id' value={device.other_patient_id}/>
        <DataElement title='referral doctor' value={
          doctorList.filter(item => item.user_id === device.doctor_user_id).map(item => item.name)
        }/>
        <DataElement title='care contact' value={
          staffList.filter(item => item.user_id === device.staff_user_id).map(item => item.name)
        }/>
        <DataElement title='clinical_notes' value={device.clinical_notes}/>
      </div>
      <EditDeviceDetails onSave={() => dispatch(setDeviceAsync(accessToken, account_id, device_id))}/>
      <AssignToPatient onSave={() => dispatch(setDeviceAsync(accessToken, account_id, device_id))}/>
      <ChangeCareContact onSave={() => dispatch(setDeviceAsync(accessToken, account_id, device_id))}/>
      <DeviceChart chartType='single' embedded={true}/>
    </>
  );
}
