import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";
import {History} from "../../../domain/Device";
import {isValid, validOrDefault} from "../../../utils/formatUtils";
import _ from "lodash"

interface Stats {
  [key: string]: {display: boolean, stat: string}
}

interface Ranges {
  [key: string]: {min: number, max: number}
}

interface DeviceChart {
  history: History | null,
  relative: number,
  resolution: number,
  from: string,
  to: string,
  byDates: boolean,
  stats: Stats,
  refreshInterval: any,
  tracker: number,
  trackerX: number,
  zoomedInRange: string[] | null,
  zoomedInHistory: History | null,
  requestInProgress: boolean,
  chartSettings: boolean,
  chartType: string,
  ranges: Ranges,
  autoScale: boolean,
  timeframe: string,
}

const initialState: DeviceChart = {
  history: null,
  relative: 300,
  resolution: 1024,
  from: '',
  to: '',
  byDates: false,
  stats: {
    'temperature': {display: true, stat: 'avg'},
    'flow_setting': {display: true, stat: 'avg'},
    'fiO2_setting': {display: true, stat: 'avg'},
    'spO2': {display: true, stat: 'avg'},
    'pulse_rate': {display: true, stat: 'avg'},
    'system_status': {display: true, stat: 'avg'},
    'respiratory_rate': {display: true, stat: 'avg'},
    'rox': {display: true, stat: 'avg'}
  },
  ranges: {
    'all': {min: 0, max: 200},
    'rox': {min: 0, max: 50},
    'respiratory_rate': {min: 0, max: 50},
    'spO2': {min: 70, max: 100},
    'pulse_rate': {min: 50, max: 200},
  },
  refreshInterval: null,
  tracker: 0,
  trackerX: 0,
  zoomedInRange: null,
  zoomedInHistory: null,
  requestInProgress: false,
  chartSettings: false,
  chartType: 'single',
  autoScale: true,
  timeframe: 'realtime',
}

export const deviceChart = createSlice({
  name: 'deviceChart',
  initialState,
  reducers: {
    setTimeframe: (state, action: PayloadAction<string>) => {
      state.timeframe = action.payload
    },
    setHistory: (state, action: PayloadAction<History | null>) => {
      state.history = action.payload
    },
    setRelative: (state, action: PayloadAction<number>) => {
      state.relative = action.payload
      state.resolution = Math.min(state.relative / 60, 1024)
      state.byDates = false
      state.from = ''
      state.to = ''
      state.history = null
      state.zoomedInHistory = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setRealtime: (state, action: PayloadAction<any>) => {
      state.relative = 300
      state.resolution = 0
      state.byDates = false
      state.from = ''
      state.to = ''
      state.refreshInterval = action.payload
      state.history = null
      state.zoomedInHistory = null
    },
    clearRefreshInterval: (state) => {
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = action.payload
      state.resolution = 1024
      state.byDates = true
      state.history = null
      state.zoomedInHistory = null
    },
    setTo: (state, action: PayloadAction<string>) => {
      state.to = action.payload
      state.resolution = 1024
      state.byDates = true
      state.history = null
      state.zoomedInHistory = null
    },
    setFromTo: (state, action: PayloadAction<string[]>) => {
      state.from = action.payload[0]
      state.to = action.payload[1]
      state.resolution = 1024
      state.byDates = state.from !== '' && state.to !== ''
      if (!state.byDates) {
        state.relative = 300
      }
      state.history = null
      state.zoomedInHistory = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setRelativeFrom: (state, action: PayloadAction<{from: string, relative: number}>) => {
      state.from = action.payload.from
      state.relative = action.payload.relative
      state.resolution = 1024
      state.history = null
      state.zoomedInHistory = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setStat: (state, action: PayloadAction<{name: string, stat: string}>) => {
      state.stats[action.payload.name].stat = action.payload.stat
      state.history = null
    },
    setRangeMin: (state, action: PayloadAction<{name: string, value: number}>) => {
      state.ranges[action.payload.name].min = action.payload.value
    },
    setRangeMax: (state, action: PayloadAction<{name: string, value: number}>) => {
      state.ranges[action.payload.name].max = action.payload.value
    },
    setDisplay: (state, action: PayloadAction<{name: string, display: boolean}>) => {
      state.stats[action.payload.name].display = action.payload.display
      state.history = null
    },
    setTracker: (state, action: PayloadAction<{tracker: number, trackerX: number}>) => {
      state.tracker = action.payload.tracker
      state.trackerX = action.payload.trackerX
    },
    setZoomedInRange: (state, action: PayloadAction<string[]>) => {
      state.zoomedInRange = action.payload
    },
    setZoomedInHistory: (state, action: PayloadAction<History | null>) => {
      state.zoomedInHistory = action.payload
      if (state.autoScale && state.zoomedInHistory && !_.isEmpty(state.zoomedInHistory.stats)) {
        const stats = state.zoomedInHistory.stats
        state.ranges['all'].max = Math.max(...([
          stats.temperature.max,
          stats.flow_setting.max,
          stats.fiO2_setting.max,
          stats.spO2.max,
          stats.pulse_rate.max,
          stats.respiratory_rate?.max].filter(e => isValid(e))))

        state.ranges['pulse_rate'].max = validOrDefault(stats.pulse_rate.max, stats.pulse_rate.min, 200)
        state.ranges['spO2'].max = validOrDefault(stats.spO2.max, stats.spO2.min, 100)
        state.ranges['respiratory_rate'].max = validOrDefault(stats.respiratory_rate?.max, stats.respiratory_rate?.min, 50)
        state.ranges['rox'].max = validOrDefault(stats.rox?.max, stats.rox?.min, 50)

        state.ranges['all'].min = Math.min(...([
          stats.temperature.min,
          stats.flow_setting.min,
          stats.fiO2_setting.min,
          stats.spO2.min,
          stats.pulse_rate.min,
          stats.respiratory_rate?.min].filter(e => isValid(e))))
        state.ranges['pulse_rate'].min = validOrDefault(stats.pulse_rate.min, stats.pulse_rate.max, 50)
        state.ranges['spO2'].min = validOrDefault(stats.spO2.min, stats.spO2.max, 70)
        state.ranges['respiratory_rate'].min = validOrDefault(stats.respiratory_rate?.min, stats.respiratory_rate?.max, 0)
        state.ranges['rox'].min = validOrDefault(stats.rox?.min, stats.rox?.max, 0)
      }
    },
    setRequestInProgress: (state, action: PayloadAction<boolean>) => {
      state.requestInProgress = action.payload
    },
    setChartSettings: (state, action: PayloadAction<boolean>) => {
      state.chartSettings = action.payload
    },
    setChartType: (state, action: PayloadAction<string>) => {
      state.chartType = action.payload
    },
    reset: (state) => {
      state.zoomedInHistory = null
      state.history = null
    },
    setAutoScale: (state, action: PayloadAction<boolean>) => {
      state.autoScale = action.payload
    },
  }
})

export const {
  setTimeframe, setHistory, setRelative, setRealtime, clearRefreshInterval,
  setFrom, setTo, setFromTo, setRelativeFrom,
  setStat, setRangeMin, setRangeMax,
  setDisplay, setTracker, setZoomedInRange, setZoomedInHistory, setRequestInProgress, setChartSettings,
  reset, setAutoScale
} = deviceChart.actions

export const selectTimeframe = (state: RootState) => state.deviceChart.timeframe
export const selectHistory = (state: RootState) => state.deviceChart.history
export const selectRelative = (state: RootState) => state.deviceChart.relative
export const selectFrom = (state: RootState) => state.deviceChart.from
export const selectTo = (state: RootState) => state.deviceChart.to
export const selectByDates = (state: RootState) => state.deviceChart.byDates
export const selectStats = (state: RootState) => state.deviceChart.stats
export const selectRanges = (state: RootState) => state.deviceChart.ranges
export const selectTracker = (state: RootState) => state.deviceChart.tracker
export const selectTrackerX = (state: RootState) => state.deviceChart.trackerX
export const selectZoomedInRange = (state: RootState) => state.deviceChart.zoomedInRange
export const selectZoomedInHistory = (state: RootState) => state.deviceChart.zoomedInHistory
export const selectRequestInProgress = (state: RootState) => state.deviceChart.requestInProgress
export const selectChartSettings = (state: RootState) => state.deviceChart.chartSettings
export const selectAutoScale = (state: RootState) => state.deviceChart.autoScale

export const setHistoryAsync = (
  accessToken: string,
  account_id: string,
  device_id: string,
  patient_count: string
): AppThunk => (dispatch, getState) => {
  const {apiUrl, headers} = getHeaders(accessToken)
  const state = getState().deviceChart
  fetch(`${apiUrl}/history/${account_id}.${device_id}.${patient_count}?relative=${state.relative}&resolution=${state.resolution}&from=${state.from}&to=${state.to}&stats=${encodeURIComponent(JSON.stringify(state.stats))}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      const newState = getState().deviceChart
      // discard result if response comes back after user changed scale
      if (newState.timeframe === state.timeframe) {
        dispatch(setHistory(data))
        dispatch(setZoomedInHistory(data))
      }
    })
    .catch((error) => handleError(dispatch, error))
}

export const getMaxTimeRangeAsync = async (
  accessToken: string,
  account_id: string,
  device_id: string,
  patient_count: string
) => {
  const {apiUrl, headers} = getHeaders(accessToken)
  const response = await fetch(`${apiUrl}/history/${account_id}.${device_id}.${patient_count}/time-range`, {
    method: 'GET',
    headers: headers,
  })
  const data = await response.json()
  return data.data.length > 0
    ? [
      data.data[0].min_time.substr(0, 10),
      data.data[0].max_time.substr(0, 10)
    ]
    : [
      new Date().toISOString().substr(0, 10),
      new Date().toISOString().substr(0, 10)
    ]
}

export const setMaxTimeRangeAsync = (
  accessToken: string,
  account_id: string,
  device_id: string,
  patient_count: string
): AppThunk => (dispatch, getState) => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/history/${account_id}.${device_id}.${patient_count}/time-range`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      const fromTo = data.data.length > 0
        ? [
          data.data[0].min_time.substr(0, 10),
          data.data[0].max_time.substr(0, 10)
        ]
        : [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ]
      dispatch(setFromTo(fromTo))
      dispatch(setHistoryAsync(accessToken, account_id, device_id, patient_count))
    })
    .catch((error) => handleError(dispatch, error))
}

export const setZoomedInHistoryAsync = (
  accessToken: string,
  account_id: string,
  device_id: string,
  patient_count: string
): AppThunk => (dispatch, getState) => {
  const {apiUrl, headers} = getHeaders(accessToken)
  const state = getState().deviceChart
  dispatch(setRequestInProgress(true))

  // @ts-ignore
  fetch(`${apiUrl}/history/${account_id}.${device_id}.${patient_count}?resolution=${state.resolution}&from=${state.zoomedInRange[0]}&to=${state.zoomedInRange[1]}&stats=${encodeURIComponent(JSON.stringify(state.stats))}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setRequestInProgress(false))
      dispatch(setZoomedInHistory(data))
    })
    .catch((error) => handleError(dispatch, error))
}

export default deviceChart.reducer;
