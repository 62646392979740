import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from "lodash"
import {
  selectHistory,
  setHistoryAsync,
  selectRelative,
  selectStats,
  setFrom,
  selectFrom,
  setHistory,
  setRealtime,
  clearRefreshInterval,
  selectTracker,
  setTracker,
  selectTo,
  setTo,
  setFromTo,
  selectByDates,
  selectZoomedInHistory,
  selectZoomedInRange,
  setZoomedInRange,
  setZoomedInHistoryAsync,
  selectRequestInProgress,
  selectChartSettings,
  setChartSettings,
  reset,
  selectRanges,
  setRangeMax,
  setRangeMin,
  selectAutoScale,
  setAutoScale,
  setMaxTimeRangeAsync,
  selectTrackerX,
  setRelativeFrom, setTimeframe, selectTimeframe, getMaxTimeRangeAsync,
} from './deviceChartSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {
  Breadcrumb, BreadcrumbItem,
  Button,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Spinner,
} from "reactstrap";
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom'
import {siteTitlePrefix} from "../../../App";
import styles from './DeviceChart.module.css';
import {ChartStatControl} from "./ChartStatControl";
import moment from "moment";
import { TimeSeries, TimeRange } from "pondjs";
// @ts-ignore
import { Charts, ChartContainer, ChartRow, YAxis, LineChart, Brush, AreaChart, ScatterChart, styler } from "react-timeseries-charts"
import {allSearchParams, formatInt} from "../../../utils/formatUtils"
import {selectDevice} from "../state/deviceStateSlice";

interface DeviceChartProps {
  chartType: string,
  embedded: boolean
}

export function DeviceChart(props: DeviceChartProps) {
  const { account_id, device_id, patient_count, patient_id } = useParams() as any
  const deviceId = device_id || patient_id.split('.')[0]
  const patientCount = patient_count || patient_id.split('.')[1]
  const current = !patient_id
  const device = useSelector(selectDevice)
  const history = useSelector(selectHistory)
  const zoomedInHistory = useSelector(selectZoomedInHistory)
  const zoomedInRangeStored = useSelector(selectZoomedInRange)
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const location = useLocation()
  const parent = location.pathname.includes('/devices/') ? 'devices' : 'patients'
  const title = `${parent === 'devices' ? 'Device' : 'Patient'} Chart`
  const relative = useSelector(selectRelative)
  const from = useSelector(selectFrom)
  const to = useSelector(selectTo)
  const byDates = useSelector(selectByDates)
  const tracker = useSelector(selectTracker)
  const trackerX = useSelector(selectTrackerX)
  const stats = useSelector(selectStats)
  const requestInProgress = useSelector(selectRequestInProgress)
  const autoScale = useSelector(selectAutoScale)

  const lineStyle = {
    temperature: {
      normal: {stroke: "#589A7D", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    flow_setting: {
      normal: {stroke: "#eeb304", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    fiO2_setting: {
      normal: {stroke: "#000b73", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    spO2: {
      normal: {stroke: "#9b430e", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    pulse_rate: {
      normal: {stroke: "rgb(166, 80, 184)", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    rox: {
      normal: {stroke: "#d34901", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#d34901", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    respiratory_rate: {
      normal: {stroke: "#5a98cb", fill: "none", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
    },
    system_status: {
      normal: {stroke: "rgb(166, 80, 184)", fill: "gray", strokeWidth: 2},
      highlighted: {stroke: "#5a98cb", fill: "gray", strokeWidth: 1},
      selected: {stroke: "steelblue", fill: "gray", strokeWidth: 1},
      muted: {stroke: "steelblue", fill: "gray", opacity: 0.4, strokeWidth: 1}
    },
  };

  const temperatureAxisStyle = {
    label: { stroke: "none", fill: "#589A7D" },
    ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
  }

  const pulseRateAxisStyle = {
    label: { stroke: "none", fill: "rgb(166, 80, 184)" },
    ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
  }

  const spO2AxisStyle = {
    label: { stroke: "none", fill: "#9b430e" },
    ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
  }

  const respiratoryRateAxisStyle = {
    label: { stroke: "none", fill: "#5a98cb" },
    ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
  }

  const roxAxisStyle = {
    label: { stroke: "none", fill: "#5a98cb" },
    ticks: { stroke: "#d34901", opacity: 1, "stroke-dasharray": "5,5" }
  }

  const trackerStyle = {
    line: { stroke: "gray", cursor: "crosshair", pointerEvents: "yes" },
    box: { fill: "red", opacity: 1, stroke: "yellow", pointerEvents: "none" },
  }

  const areaStyle = styler([
    { key: "therapy", color: "rgba(187,213,243,0.6)" },
    { key: "standby", color: "rgba(237,248,220,0.77)" },
    { key: "spO2_area", color: "rgba(155,67,14,0.25)" },
    { key: "pulse_rate_area", color: "rgba(166,80,184,0.25)" },
  ]);

  const displayedColumns = Array.from(Object.keys(stats).filter(e => stats[e].display))
  const ranges = useSelector(selectRanges)
  const data = {
    name: "device chart",
    columns: ["time"].concat(displayedColumns).concat(['standby', 'therapy', 'spO2_area', 'pulse_rate_area']),
    points: (history != null ? history.data : []).map((item) => [
      moment(item.timestamp).valueOf() as any].concat(
      displayedColumns.map(e => item[e as keyof typeof item])
    ).concat([
        item['system_status'] === '1' ? 100: 0,
        item['system_status'] === '2' ? 100: 0,
        parseFloat(item['spO2']) >= 127 ? 0: parseFloat(item['spO2']),
        parseFloat(item['pulse_rate']) >= 255 ? 0: parseFloat(item['pulse_rate']),
      ]
    ))
  }

  const zoomedInData = {
    name: "device chart",
    columns: ["time"].concat(displayedColumns).concat(['standby', 'therapy', 'spO2_area', 'pulse_rate_area']),
    points: (zoomedInHistory != null ? zoomedInHistory.data : []).map((item) => [
      moment(item.timestamp).valueOf() as any].concat(
      displayedColumns.map(e => item[e as keyof typeof item])
    ).concat([
        item['system_status'] === '1' ? 100: 0,
        item['system_status'] === '2' ? 100: 0,
        parseFloat(item['spO2']) >= 127 ? 0: parseFloat(item['spO2']),
        parseFloat(item['pulse_rate']) >= 255 ? 0: parseFloat(item['pulse_rate']),
      ]
    ))
  }

  const respiratory_rate_stat = zoomedInHistory != null ? zoomedInHistory.stats.respiratory_rate : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const spO2_stat = zoomedInHistory != null ? zoomedInHistory.stats.spO2 : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const pulse_rate_stat = zoomedInHistory != null ? zoomedInHistory.stats.pulse_rate : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const temperature_stat = zoomedInHistory != null ? zoomedInHistory.stats.temperature : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const flow_setting_stat = zoomedInHistory != null ? zoomedInHistory.stats.flow_setting : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const fiO2_setting_stat = zoomedInHistory != null ? zoomedInHistory.stats.fiO2_setting : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const rox_stat = zoomedInHistory != null ? zoomedInHistory.stats.rox : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const timeseries = new TimeSeries(data)
  const zoomedInTimeseries = new TimeSeries(zoomedInData)
  const timeRange = zoomedInTimeseries.timerange()
  const zoomedInRange = zoomedInRangeStored ? new TimeRange(zoomedInRangeStored) : zoomedInTimeseries.timerange()
  const event = zoomedInTimeseries.atTime(new Date(tracker))
  const zoomedHistory = () => dispatch(setZoomedInHistoryAsync(accessToken, account_id, deviceId, patientCount))
  const debouncedZoomedHistory = useCallback(_.debounce(zoomedHistory, 100, {'leading': false, 'trailing': true}), [])

  const handleTimeRangeChange = (timerange: TimeRange) => {
    dispatch(setZoomedInRange([timerange.begin().toISOString(), timerange.end().toISOString()]))
    debouncedZoomedHistory()
  }

  const chartSettings = useSelector(selectChartSettings)

  const refresh = (action: any) => {
    if (action) {
      dispatch(action)
    } else {
      dispatch(setHistory(null))
    }
    dispatch(setHistoryAsync(accessToken, account_id, deviceId, patientCount))
  }

  const refreshRealtime = () => {
    dispatch(setHistoryAsync(accessToken, account_id, deviceId, patientCount))
  }

  const chartType = props.chartType

  const [searchParams, setSearchParams] = useSearchParams({});

  const timeframeParam = searchParams.get('timeframe') || (current ? 'realtime' : '...')
  const timeframeStored = useSelector(selectTimeframe)

  const timeframeAction = async (timeframe: string, from: string | null = null, to: string | null = null) => {
    if (timeframe === 'realtime') {
      dispatch(setTimeframe(timeframe))
      dispatch(setRealtime(!props.embedded ? setInterval(() => refreshRealtime(), 2000) : null))
    } else if (timeframe.endsWith('hr')) {
      const hours = parseInt(timeframe.substr(0, timeframe.length - 2))
      dispatch(setTimeframe(timeframe))
      refresh(setRelativeFrom({from: device.therapy_sessions.split(',')[0], relative: 3600 * hours}))
    } else if (timeframe === '...') {
      dispatch(setTimeframe('dates'))
      dispatch(reset())
      timeframeAction('dates')
      timeframe = 'dates'
    } else if (timeframe.match('dates')) {
      const fromTo = (!from && !to) ? await getMaxTimeRangeAsync(accessToken, account_id, deviceId, patientCount) : [from, to]
      let [, _from, _to] = timeframeParam.split('*')
      _from = fromTo[0] || _from || new Date().toISOString().substr(0, 10)
      _to = fromTo[1] || _to || new Date().toISOString().substr(0, 10)
      timeframe = ['dates', _from, _to].join('*')
      dispatch(setTimeframe(timeframe))
      refresh(setFromTo( [_from, _to]))
    }
    setSearchParams({...allSearchParams(searchParams), timeframe: timeframe})
  }

  useEffect(() => {
    if (timeframeStored !== timeframeParam || !searchParams.get('timeframe')) {
      timeframeAction(timeframeParam)
    }
    document.title = siteTitlePrefix + title
    if (!current) {
      dispatch(setMaxTimeRangeAsync(accessToken, account_id, deviceId, patientCount))
    }
    return () => {
      dispatch(reset())
      dispatch(clearRefreshInterval())
    }
  }, [dispatch])

  if (!history && patient_id && byDates && timeframeParam === '...') {
    const timeframe = ['dates', from, to].join('*')
    if (props.embedded)
      return (<Navigate to={`/past/${parent}/${account_id}/${patient_id}?timeframe=${timeframe}`} replace={true} />)
    else
      return (<Navigate to={`/past/${parent}/${account_id}/${patient_id}/chart?timeframe=${timeframe}`} replace={true} />)
  }
  console.log('rox_stat', rox_stat)
  console.log('respiratory_rate_stat', respiratory_rate_stat)

  // @ts-ignore
  return (
    <>
      {!props.embedded ? (
        <>
          <h1>{title}</h1>
          <Breadcrumb tag="nav">
            <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
            {
              current ? (
                <>
                  <BreadcrumbItem tag="a" href={`/current/${parent}/${account_id}`}>{parent === 'devices' ? 'Devices': 'Patients'}</BreadcrumbItem>
                  <BreadcrumbItem tag="a" href={`/${parent}/${account_id}/${deviceId}/${patientCount}`}>{deviceId}</BreadcrumbItem>
                </>
              ): (
                <>
                  <BreadcrumbItem tag="a" href={`/past/${parent}/${account_id}`}>{parent === 'devices' ? 'Prior devices' : 'Prior patients'}</BreadcrumbItem>
                  <BreadcrumbItem tag="a" href={`/past/${parent}/${account_id}/${patient_id}`}>{deviceId}</BreadcrumbItem>
                </>
              )
            }
            <BreadcrumbItem active tag="span">
              {title}
              <svg role="button" className="bi mx-2" width="20" height="20" fill="currentColor"
                   onClick={() => refresh(null)}>
                <use xlinkHref="/bootstrap-icons.svg#repeat"/>
              </svg>
            </BreadcrumbItem>
          </Breadcrumb>
        </>
      ) : (
        <hr/>
      )}
      <Modal isOpen={chartSettings} >
        <ModalHeader>Chart settings</ModalHeader>
        <ModalBody>
          <div className={styles.chartDataSelection}>
            <ChartStatControl data='temperature' refresh={refresh}/>
            <ChartStatControl data='flow_setting' refresh={refresh}/>
            <ChartStatControl data='fiO2_setting' refresh={refresh}/>
            <ChartStatControl data='spO2' refresh={refresh}/>
            <ChartStatControl data='pulse_rate' refresh={refresh}/>
            <ChartStatControl data='respiratory_rate' refresh={refresh}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => dispatch(setChartSettings(false))}>Close</Button>
        </ModalFooter>
      </Modal>
      <div className={styles.timeRangeToggler}>
        <Label for="autoScale">Auto scale</Label>
        <Input
          className="mx-lg-2"
          name="autoScale"
          id="autoScale"
          type="checkbox"
          checked={autoScale}
          onChange={e => dispatch(setAutoScale(e.target.checked))}
        />
        {current &&
          (<>
            <button type="button" className={timeframeParam === 'realtime' ? 'btn btn-dark m-1' : 'btn btn-light m-1'}
                    onClick={() => timeframeAction('realtime')}>Realtime</button>
            <button type="button" className={timeframeParam === '2hr'  ? "btn btn-dark m-1" : "btn btn-light m-1"}
                    onClick={() => timeframeAction('2hr')}>2 hours</button>
            <button type="button" className={timeframeParam === '6hr' ? "btn btn-dark m-1" : "btn btn-light m-1"}
                    onClick={() => timeframeAction('6hr')}>6 hours</button>
            <button type="button" className={timeframeParam === '12hr' ? "btn btn-dark m-1" : "btn btn-light m-1"}
                    onClick={() => timeframeAction('12hr')}>12 hours</button>
          </>)
        }
        <button type="button" className={timeframeParam.match('dates') ? 'btn btn-dark m-1' : 'btn btn-light m-1'}
                onClick={() => timeframeAction('...')}>By Dates</button>
        <div className={styles.fromToDate}>
          <Label for="from_date" className="my-2">From</Label>
          <Input
            type="date"
            id="from_date"
            disabled={!timeframeParam.match('dates')}
            value={from}
            onChange={e => timeframeAction('dates', e.target.value, null)}
          />
          <Label for="to_date" className="my-2">To</Label>
          <Input
            type="date"
            id="to_date"
            disabled={!timeframeParam.match('dates')}
            value={to}
            onChange={e => timeframeAction('dates', null, e.target.value)}
          />
          <svg role="button" className="bi mx-2" width="20" height="20" fill="currentColor"
               onClick={() => dispatch(setChartSettings(true))}>
            <use xlinkHref="/bootstrap-icons.svg#gear"/>
          </svg>
          {requestInProgress && <Spinner animation="border" size="sm"/>}
        </div>
      </div>
      {zoomedInHistory == null ? (
        <div className={styles.spinnerSpacer}>
          <Spinner animation="border" className="m-5"/>
        </div>
        ) : !timeRange ? (
          <h2 className="text-center m-5 p-5 bg-warning bg-opacity-25">No data</h2>
        ) :
        <>
          <table><tbody>
          <tr>
          <td width={100} className="align-top">
            {chartType === 'single' ? (
              <div className={styles.rangeControl}>
                <Label for="allMax" className="small">Max</Label>
                <Input id="allMax"
                       bsSize="sm"
                       className="mb-2"
                       size={3}
                       disabled={autoScale}
                       value={ranges['all'].max}
                       onChange={e => dispatch(setRangeMax({name: 'all', value: parseInt(e.target.value) || 0 }))}
                />
                <Label for="allMin" className="small">Min</Label>
                <Input id="allMin"
                       bsSize="sm"
                       size={3}
                       disabled={autoScale}
                       value={ranges['all'].min}
                       onChange={e => dispatch(setRangeMin({name: 'all', value: parseInt(e.target.value) || 0 }))}
                />
                <hr/>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.temperature.normal.stroke}}>Temp</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.temperature.normal.stroke}}>
                    {formatInt(temperature_stat.last)}
                  </div>
                </div>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.flow_setting.normal.stroke}}>Flow</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.flow_setting.normal.stroke}}>
                    {formatInt(flow_setting_stat.last)}
                  </div>
                </div>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.fiO2_setting.normal.stroke}}>FiO2</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.fiO2_setting.normal.stroke}}>
                    {formatInt(fiO2_setting_stat.last)}
                  </div>
                </div>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.spO2.normal.stroke}}>SpO2</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.spO2.normal.stroke}}>
                    {formatInt(spO2_stat.last)}
                  </div>
                  <div className={styles.stats_minmax}>
                    {formatInt(spO2_stat.max)}
                    <br/>
                    {formatInt(spO2_stat.min)}
                  </div>
                </div>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.pulse_rate.normal.stroke}}>Pulse</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.pulse_rate.normal.stroke}}>
                    {formatInt(pulse_rate_stat.last)}
                  </div>
                  <div className={styles.stats_minmax}>
                    {formatInt(pulse_rate_stat.max)}
                    <br/>
                    {formatInt(pulse_rate_stat.min)}
                  </div>
                </div>
                {respiratory_rate_stat && <div className={styles.legend}>
                  <h4 style={{color: lineStyle.respiratory_rate.normal.stroke}}>RR</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.respiratory_rate.normal.stroke}}>
                    {formatInt(respiratory_rate_stat.last)}
                  </div>
                  <div className={styles.stats_minmax}>
                    {formatInt(respiratory_rate_stat.max)}
                    <br/>
                    {formatInt(respiratory_rate_stat.min)}
                  </div>
                </div>}
              </div>
            ) : (
              <div>
                {respiratory_rate_stat && <>
                <div className={styles.rangeControl}>
                  <Label for="respiratory_rateMax" className="small">Max: {formatInt(respiratory_rate_stat.max)}</Label>
                  <Input id="respiratory_rateMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['respiratory_rate'].max}
                         onChange={e => dispatch(setRangeMax({name: 'respiratory_rate', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="respiratory_rateMin" className="small">Min: {formatInt(respiratory_rate_stat.min)}</Label>
                  <Input id="respiratory_rateMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['respiratory_rate'].min}
                         onChange={e => dispatch(setRangeMin({name: 'respiratory_rate', value: parseInt(e.target.value) || 0 }))}
                  />
                    <Label className="small">Avg {formatInt(respiratory_rate_stat.avg)}</Label><br/>
                  </div>
                </>}
                <div className={styles.rangeControl}>
                <Label for="spO2Max" className="small">Max {formatInt(spO2_stat.max)}</Label>
                <Input id="spO2Max"
                       bsSize="sm"
                       className="mb-2"
                       size={3}
                       disabled={autoScale}
                       value={ranges['spO2'].max}
                       onChange={e => dispatch(setRangeMax({name: 'spO2', value: parseInt(e.target.value) || 0 }))}
                />
                <Label for="spO2Min" className="small">Min {formatInt(spO2_stat.min)}</Label>
                <Input id="spO2Min"
                       bsSize="sm"
                       size={3}
                       disabled={autoScale}
                       value={ranges['spO2'].min}
                       onChange={e => dispatch(setRangeMin({name: 'spO2', value: parseInt(e.target.value) || 0 }))}
                />
                  <Label className="small">Avg {formatInt(spO2_stat.avg)}</Label><br/>
                </div>
                <div className={styles.rangeControl}>
                <Label for="pulse_rateMax" className="small">Max {formatInt(pulse_rate_stat.max)}</Label>
                <Input id="pulse_rateMax"
                       bsSize="sm"
                       className="mb-2"
                       size={3}
                       disabled={autoScale}
                       value={ranges['pulse_rate'].max}
                       onChange={e => dispatch(setRangeMax({name: 'pulse_rate', value: parseInt(e.target.value) || 0 }))}
                />
                <Label for="pulse_rateMin" className="small">Min {formatInt(pulse_rate_stat.min)}</Label>
                <Input id="pulse_rateMin"
                       bsSize="sm"
                       size={3}
                       disabled={autoScale}
                       value={ranges['pulse_rate'].min}
                       onChange={e => dispatch(setRangeMin({name: 'pulse_rate', value: parseInt(e.target.value) || 0 }))}
                />
                  <Label className="small">Avg {formatInt(pulse_rate_stat.avg)}</Label><br/>
                </div>
              </div>
            )
            }
          </td>
          <td>
          <div style={{position: "relative"}}>
            {chartType === 'single' &&
              <div style={{
                position: "absolute",
                left: trackerX + (trackerX > 900 ? -210 : 0),
                display: (trackerX > 60 ? 'block': 'none')
              }}>
                <div className={styles.markerStyleTime}>
                  {moment(tracker).local().format('L')} {moment(tracker).local().format('LTS')}
                </div>
                <table className={styles.markerStyleValues}>
                  <tbody>
                    <tr>
                      <th style={{color: lineStyle.temperature.normal.stroke}}>Temperature</th>
                      <td>{event.get("temperature")}</td>
                    </tr>
                    <tr>
                      <th style={{color: lineStyle.flow_setting.normal.stroke}}>Flow setting</th>
                      <td>{event.get("flow_setting")}</td>
                    </tr>
                    <tr>
                      <th style={{color: lineStyle.fiO2_setting.normal.stroke}}>FiO2 setting</th>
                      <td>{event.get("fiO2_setting")}</td>
                    </tr>
                    <tr>
                      <th style={{color: lineStyle.spO2.normal.stroke}}>SpO2</th>
                      <td>{event.get("spO2")}</td>
                    </tr>
                    <tr>
                      <th style={{color: lineStyle.pulse_rate.normal.stroke}}>Pulse rate</th>
                      <td>{event.get("pulse_rate")}</td>
                    </tr>
                    {respiratory_rate_stat &&
                      <tr>
                        <th style={{color: lineStyle.respiratory_rate.normal.stroke}}>Respiratory rate</th>
                        <td>{event.get("respiratory_rate")}</td>
                      </tr>
                    }
                    {rox_stat &&
                      <tr>
                        <th style={{color: lineStyle.rox.normal.stroke}}>ROX</th>
                        <td>{formatInt(event.get("rox"))}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
            {chartType === 'multiple' &&
              <>
                <div style={{
                  position: "absolute",
                  left: trackerX + (trackerX > 900 ? -210 : 0),
                  display: (trackerX > 60 ? 'block': 'none')
                }}>
                  <div className={styles.markerStyleTime}>
                    {moment(tracker).local().format('L')} {moment(tracker).local().format('LTS')}
                  </div>
                  <table className={styles.markerStyleValues}>
                    <tbody>
                    <tr>
                      <th style={{color: lineStyle.spO2.normal.stroke}}>SpO2</th>
                      <td>{event.get("spO2")}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{
                  position: "absolute",
                  top:240,
                  left: trackerX + (trackerX > 900 ? -210 : 0),
                  display: (trackerX > 60 ? 'block': 'none')
                }}>
                  <table className={styles.markerStyleValues}>
                    <tbody>
                    <tr>
                      <th style={{color: lineStyle.pulse_rate.normal.stroke}}>Pulse rate</th>
                      <td>{event.get("pulse_rate")}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                {respiratory_rate_stat && <div style={{
                    position: "absolute",
                    top:480,
                    left: trackerX + (trackerX > 900 ? -210 : 0),
                    display: (trackerX > 60 ? 'block': 'none')
                  }}>
                  <table className={styles.markerStyleValues}>
                    <tbody>
                    <tr>
                      <th style={{color: lineStyle.respiratory_rate.normal.stroke}}>Respiratory rate</th>
                      <td>{event.get("respiratory_rate")}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>}
                {rox_stat && <div style={{
                  position: "absolute",
                  top:720,
                  left: trackerX + (trackerX > 900 ? -210 : 0),
                  display: (trackerX > 60 ? 'block': 'none')
                }}>
                  <table className={styles.markerStyleValues}>
                    <tbody>
                    <tr>
                      <th style={{color: lineStyle.rox.normal.stroke}}>ROX</th>
                      <td>{event.get("rox")}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>}
              </>
            }
          </div>
          <ChartContainer
            timeRange={timeRange}
            trackerPosition={new Date(tracker)}
            trackerStyle={trackerStyle}
            onTrackerChanged={(t: Date, scale: any) => dispatch(setTracker({tracker: t?.getTime(), trackerX: t && scale(t)}))}
            width={1100}
            showGrid={true}>
            <ChartRow height="775" visible={chartType === 'single'}>
              <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false}
                     width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <YAxis id="axis1_all" label="All" min={ranges['all'].min} max={ranges['all'].max}
                     width="40" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <Charts>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["standby"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <LineChart axis="axis1_all" style={lineStyle} series={zoomedInTimeseries} columns={[
                  'temperature',
                  'flow_setting',
                  'fiO2_setting',
                  'respiratory_rate'
                ]}/>
                <LineChart axis="axis1_all" style={lineStyle} series={zoomedInTimeseries} columns={[
                  'spO2',
                  'pulse_rate',
                ]} radius = '0.5'/>
                <LineChart axis="axis1_rox" style={lineStyle} series={zoomedInTimeseries} columns={[
                  'rox'
                ]}/>
                {/*
                <AreaChart axis="axis1_all" series={zoomedInTimeseries} columns={{up: ["spO2_area"], down: []}} fillOpacity={0.1} style={areaStyle}/>
                <AreaChart axis="axis1_all" series={zoomedInTimeseries} columns={{up: ["pulse_rate_area"], down: []}} fillOpacity={0.1} style={areaStyle}/>
                */}
              </Charts>
              {rox_stat && <YAxis id="axis1_rox" label="ROX" min={ranges['rox'].min} max={ranges['rox'].max}
                     width="40" type="linear" format=",.2f" showGrid style={roxAxisStyle}/>}
            </ChartRow>
            <ChartRow height="240" visible={chartType === 'multiple'}>
              <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false} width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <YAxis tickCount={7} id="axis2" label="spO2" min={ranges['spO2'].min} max={ranges['spO2'].max}  width="60" type="linear" showGrid format=",d" style={spO2AxisStyle}/>
              <Charts>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["standby"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <ScatterChart axis="axis2" style={lineStyle} series={zoomedInTimeseries} columns={['spO2']}/>
                <AreaChart axis="axis2" series={zoomedInTimeseries} columns={{up: ["spO2_area"], down: []}} fillOpacity={0.4} style={areaStyle}/>
              </Charts>
            </ChartRow>
            <ChartRow height="240" visible={chartType === 'multiple'}>
              <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false} width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <YAxis tickCount={11} id="axis3" label="pulse rate" min={ranges['pulse_rate'].min} max={ranges['pulse_rate'].max} width="60" type="linear" format=",d" showGrid style={pulseRateAxisStyle}/>
              <Charts>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["standby"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <ScatterChart axis="axis3" style={lineStyle} series={zoomedInTimeseries} columns={['pulse_rate']}/>
                <AreaChart axis="axis3" series={zoomedInTimeseries} columns={{up: ["pulse_rate_area"], down: []}} fillOpacity={0.4} style={areaStyle}/>
              </Charts>
            </ChartRow>
            <ChartRow height="240" visible={chartType === 'multiple' && respiratory_rate_stat !== undefined}>
              <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false} width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <YAxis id="axis1_respiratory_rate" label="respiratory rate" min={ranges['respiratory_rate'].min} max={ranges['respiratory_rate'].max} width="60" type="linear" format=",d" showGrid style={respiratoryRateAxisStyle}/>
              <Charts>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["standby"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <LineChart axis="axis1_respiratory_rate" style={lineStyle} series={zoomedInTimeseries} columns={['respiratory_rate']}/>
              </Charts>
            </ChartRow>
            <ChartRow height="240" visible={chartType === 'multiple' && rox_stat !== undefined}>
              <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false} width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
              <YAxis tickCount={11} id="axis4" label="rox" min={ranges['rox'].min} max={ranges['rox'].max} width="60" type="linear" format=",.2f" showGrid style={roxAxisStyle}/>
              <Charts>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <AreaChart axis="axis1_ss" series={zoomedInTimeseries} columns={{up: ["standby"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                <LineChart axis="axis4" style={lineStyle} series={zoomedInTimeseries} columns={['rox']}/>
              </Charts>
            </ChartRow>
          </ChartContainer>
          {byDates && history && zoomedInHistory && timeseries && (timeseries as any).timerange() &&
            <ChartContainer
              timeRange={(timeseries as any).timerange()}
              width={1060}>
              <ChartRow height="80">
                <Brush
                  timeRange={zoomedInRange}
                  allowSelectionClear
                  onTimeRangeChanged={handleTimeRangeChange}
                />
                <YAxis id="axis1_ss" label="All" min={0} max={1} visible={false} width="0" type="linear" format=",d" showGrid style={temperatureAxisStyle}/>
                <YAxis id="axis4" label="spO2" min={70} max={100} width={60} type="linear" format=",d" style={spO2AxisStyle}/>
                <YAxis id="axis5" label="pulse rate" min={50} max={200} width={60} type="linear" format=",d" showGrid style={pulseRateAxisStyle}/>
                <Charts>
                  <AreaChart axis="axis1_ss" series={timeseries} columns={{up: ["therapy"], down: []}} fillOpacity={0.4} style={areaStyle}/>
                  <LineChart axis="axis4" style={lineStyle} series={timeseries} columns={['spO2']}/>
                  <LineChart axis="axis5" style={lineStyle} series={timeseries} columns={['pulse_rate']}/>
                </Charts>
              </ChartRow>
            </ChartContainer>}
          </td>
          {rox_stat &&
            <td width={100} className="align-top px-2">
              <Label for="roxMax" className="small">Max</Label>
              <Input id="roxMax"
                     bsSize="sm"
                     className="mb-2"
                     size={3}
                     disabled={autoScale}
                     value={ranges['rox'].max}
                     onChange={e => dispatch(setRangeMax({name: 'rox', value: parseInt(e.target.value) || 0 }))}
              />
              <Label for="roxMin" className="small">Min</Label>
              <Input id="roxMin"
                     bsSize="sm"
                     size={3}
                     disabled={autoScale}
                     value={ranges['rox'].min}
                     onChange={e => dispatch(setRangeMin({name: 'rox', value: parseInt(e.target.value) || 0 }))}
              />
              <hr/>
              <div className={styles.rangeControl}>
                <div className={styles.legend}>
                  <h4 style={{color: lineStyle.rox.normal.stroke}}>ROX</h4>
                  <div className={styles.stats_value} style={{color: lineStyle.rox.normal.stroke}}>
                    {formatInt(rox_stat.last)}
                  </div>
                  <div className={styles.stats_minmax}>
                    {formatInt(rox_stat.max)}
                    <br/>
                    {formatInt(rox_stat.min)}
                  </div>
                </div>
              </div>
            </td>
          }
          </tr>
          </tbody></table>
        </>
      }
    </>
  )
}
