import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectList, setListAsync} from './pastDevicesSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Breadcrumb, BreadcrumbItem, Spinner, Table} from "reactstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

export function PastDevices() {
  const { account_id } = useParams() as any
  const devices = useSelector(selectList);
  const list = devices[account_id]
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const navigate = useNavigate()
  const title = 'Prior Devices'

  useEffect(() => {
      dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem active tag="span">{title}</BreadcrumbItem>
      </Breadcrumb>
      <div className="tab-toggler">
        <button type="button" className="btn btn-light m-1"
                onClick={() => navigate(`/current/devices/${account_id}`)}>Current</button>
        <button type="button" className="btn btn-dark m-1"
                onClick={() => navigate(`/past/devices/${account_id}`)}>Prior</button>
      </div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Serial number</th>
          <th>First therapy date</th>
          <th>Last therapy session</th>
          <th>Treatment time</th>
        </tr>
      </thead>
      <tbody>
      {list.map(item => (
        <tr key={`${item.device_id}-${item.patient_count}`}>
          <td>
            <Link to={`/past/devices/${account_id}/${item.device_id}.${item.patient_count}`}>{item.serial_number || 'N/A'}</Link>
          </td>
          <td>{item.first_therapy_date}</td>
          <td>{item.therapy_sessions ? moment.utc(item.therapy_sessions.split(',').pop()).local().format('LLL'): ''}</td>
          <td>{item.total_therapy_time}</td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}
